.logo {
	height: 160px;
	padding-top: 35px;
	text-align: center;
}
.logo .avatar {
	width: 80px;
	height: 80px;
	margin: 0 auto;
	overflow: hidden;
	border: 3px solid #e7e7e7;
	border-radius: 50%;
}
.logo .avatar img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}
.logo h4 {
	margin-top: 5px;
	color: #e7e7e7;
	font-size: 16px;
	font-weight: bold;
}
