.header {
	background-color: #fff;
	height: 64px;
	padding: 0;
	position: relative;
}

.header .trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 20px;
	position: absolute;
	margin-top: 25px;
}

.header .navbar-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 30px 0 70px;
}

.exit-btn {
	margin-left: 30px;
}

.header .navbar-header .navbar-brand {
	color: #333;
	font-size: 20px;
	font-weight: bold;
}
.ant-avatar-lg {
	width: 64px;
	height: 32px;
	line-height: 32px;
	margin-bottom: 2px;
	background: cornflowerblue;
}