.admin-pane {
	min-height: 100%;
}
.admin-content {
	background-color: #fff;
	margin: 24px 16px 0;
	padding: 24px;
	min-height: 500px;
}
.admin-pane .admin-footer {
	text-align: center;
}
