.home {
	width: 100%;
	height: 100%;
}
.home-top {
	display: flex;
	align-items: center;
}
.home-content {
	justify-content: space-between;
	margin: 20px 0;
	width: 100%;
}

